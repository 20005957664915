import { catchError, combineLatest, filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { RequestState, RouterStateManagerService } from './../../shared/services/router-state-manager/router-state-manager.service';
import { FHService, ModalService, Request } from 'npx-family-happy-common';
import { RequestSummary } from './../../shared/models/request-summary.model';
import { UserStorageService } from './../../authentication/user-storage/user-storage.service';
import { UntypedFormGroup, UntypedFormControl, ReactiveFormsModule } from '@angular/forms';
import { RequestService } from '../request-service/request.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProvinceService } from 'src/app/shared/services/province-service/province.service';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { CommonModule } from '@angular/common';
import { OperatorFilterComponent } from 'src/app/shared/components/custom-select/operator-filter/operator-filter.component';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';
import { FHServiceSelectComponent } from "../../shared/components/custom-select/fh-service-select/fh-service-select.component";
import { cellFormatter, emailFormatter } from 'src/app/shared/utils.functions';


@Component({
  selector: 'app-request-list',
  templateUrl: './request-list.component.html',
  styleUrls: ['./request-list.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, OperatorFilterComponent, NgbPaginationModule, AlertComponent, FHServiceSelectComponent]
})
export class RequestListComponent implements OnInit, OnDestroy {
  richieste: RequestSummary[] = [];
  original: RequestSummary[] = [];
  showAlert = false;
  selectedId = 0;

  filters!: UntypedFormGroup;

  hideErrServiceField: boolean = true

  sortBy: string | null = null;
  sortDesc: boolean = true;
  sortDescControl = new UntypedFormControl(true)
  elementsPerPage = new UntypedFormControl(10);
  showPageButton = true;
  operators: any[] = [];
  private province = '';
  private requestState!: RequestState;
  private unsubscribe = new Subject<void>();
  isAdmin = false;
  requestsList: RequestSummary[] = []

  pageView: number = 1;
  pageSize: number = 10;
  collectionSize: number = 0
  filterState: any = {}
  serviceIDfilter!: number | null

  //script var
  pageScript!: Observable<any>

  constructor(private requestService: RequestService,
    private userStorage: UserStorageService,
    private provinceService: ProvinceService,
    private stateManager: RouterStateManagerService,
    private modalService: ModalService,
    private authService: AuthService) {
    this.userStorage.getUser()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user) => {
        if (user) {
          const index = this.operators.findIndex(item => item === user.name);
          if (index === -1) {
            this.operators.push(user.name);
          }
        }
        this.authService.getToken().subscribe((token) => this.isAdmin = this.authService.isUserAdmin(token) ?? false);
      })

    let subscriptions: any = {};
    subscriptions.province = this.provinceService.getProvince().pipe(
      catchError((error) => { return error; }));
    subscriptions.state = this.stateManager.getRequestState().pipe(
      tap((change) => {
        if (change.listFilters.serviceID) {
          this.serviceIDfilter = change.listFilters.serviceID
        }
      }),
      catchError((error) => { return error; }));
    this.initiateValues()

    combineLatest(subscriptions).pipe(
      takeUntil(this.unsubscribe)).subscribe((state: any) => {
        this.requestState = state.state
        this.province = state.province
        this.getRequestList()
      })
    // this.requestService.getPage(this.processFilters(this.requestState.listFilters), 125, 50) SCRIPT TO CONVERT ADDRESSES INTO COORDINATES
  }


  ngOnInit(): void {
    this.setFormValue()

    this.filters.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((filters) => {
      this.requestState.listFilters = filters
      this.stateManager.setRequestState(this.requestState, false)
      this.pageView = 1
    })
    this.elementsPerPage.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((number) => {
      this.pageView = 1
      this.pageSize = number
      this.getRequestList()
    })
    this.sortDescControl.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((control) => {
      if (control === true) {
        this.sortDesc = true
      } else {
        this.sortDesc = false
      }
      this.getRequestList()
    })
  }

  getRequestList() {
    this.requestService.getRequests(this.processFilters(this.requestState.listFilters), this.pageView - 1, this.pageSize, this.sortDesc ? 'DESC' : 'ASC', this.sortBy).pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.collectionSize = res.totalElements
      // this.convertAddress(res.content) //SCRIPT --- CONVERTS ADDRESSES INTO COORDINATES
      this.populateRequests([...res.content]);
    })
  }

  onAlertConfimation(event: boolean) {
    if (event) {
      const index = this.richieste.findIndex(item => +item.id === this.selectedId);
      this.richieste.splice(index, 1);
      this.original.splice(index, 1);
      this.requestService.deleteRequest(this.selectedId, this.trash?.value === 'true')
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this.modalService.showSuccessMessage(`Richiesta eliminata.`);
        });
    }
    this.selectedId = 0;
    this.showAlert = false;
  }

  restoreRequest(id: number) {
    this.requestService.restoreRequest(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.modalService.showSuccessMessage(`Richiesta ${id} ripristinata.`);
        const index = this.richieste.findIndex(item => +item.id === this.selectedId);
        this.richieste.splice(index, 1);
        this.original.splice(index, 1);
      });
  }

  duplicateRequest(id: number) {
    this.requestService.duplicateRequest(id).subscribe((request) => {
      this.modalService.showSuccessMessage(`Richiesta duplicata con successo! Nuova richiesta numero: ${request.id}`);
      this.resetFilters(); // Update requests list by resetting filters
    })
  }

  deleteRequest(id: number) {
    this.selectedId = id;
    this.showAlert = true;
  }

  openDetail(id: string) {
    if (id === 'new') {
      this.requestState.id = 'new';
    } else {
      if (this.trash?.value === 'false') {
        this.requestState.id = 'F#' + id;
      } else {
        this.requestState.id = 'T#' + id;
      }
    }
    this.requestState.route = `request-detail/${id}`;
    this.requestState.step = 0;
    this.requestState.request = undefined;
    this.requestState.requestDetail = undefined;
    this.ngOnDestroy();
    this.stateManager.setRequestState(this.requestState);
  }

  generateUrl(docRef: string) {
    if (docRef === 'new') {
      this.requestState.id = 'new';
    } else {
      if (this.trash?.value === 'false') {
        this.requestState.id = 'F#' + docRef;
      } else {
        this.requestState.id = 'T#' + docRef;
      }
    }
    this.requestState.route = `request-detail/${docRef}`;
    this.requestState.step = 0;
    this.requestState.request = undefined;
    this.stateManager.setRequestState(this.requestState);
  }

  /* Getters */

  get trash() {
    return this.filters.get('trash');
  }

  resetFilters() {
    this.richieste = [];
    this.filters.reset({
      id: null,
      parent: null,
      email: null,
      phone: null,
      babysitters: null,
      city: null,
      category: 'TUTTI',
      zone: null,
      status: 'TUTTI',
      operator: null,
      trash: 'false',
      province: '',
      page: 0
    });
  }

  /* Private functions */

  private processFilters(filters: any) {

    let formattedFilters: any = {};
    formattedFilters.page = 0

    if (Object.keys(filters).length > 0) {
      if (filters.id) {
        formattedFilters.id = filters.id;
      }
      if (filters.parent !== '') {
        formattedFilters.parent = filters.parent;
      }
      if (filters.email !== '') {
        if(filters.email !== null){
          let formattedMail = emailFormatter(filters.email);
          formattedFilters.email = formattedMail;
        }
      }
      if (filters.phone !== '') {
        if(filters.phone !== null){
          let formattedPhone = cellFormatter(filters.phone);
          formattedFilters.phone = formattedPhone;
        }
      }
      if (filters.city !== '') {
        formattedFilters.city = filters.city;
      }
      if (filters.zone !== '') {
        formattedFilters.zone = filters.zone;
      }
      if (filters.operator !== 'default') {
        formattedFilters.operator = filters.operator;
      }
      if (filters.page !== null) {
        formattedFilters.page = filters.page;
      }
      if (filters.status !== 'TUTTI') {
        formattedFilters.status = filters.status;
      }
      if (filters.customerType !== 'TUTTI') {
        // this.filters.get('serviceID')?.setValue(null)
        formattedFilters.customerType = filters.customerType;
      }
      if (filters.trash === 'true') {
        formattedFilters.status = 'DELETED';
      }
    }
    if (this.province !== '') {
      formattedFilters.province = this.province
    }
    if (filters.category !== 'TUTTI') {
      formattedFilters.category = filters.category
    }
    if (filters.serviceID !== null) {
      formattedFilters.serviceID = filters.serviceID
    }
    return formattedFilters;
  }

  private populateRequests(array: Request[]) {
    if (this.collectionSize > 0) {
      this.original = array.map((item) => {
        return {
          id: item.id ? '' + item.id : '-',
          name: (item.requestPersonalInfo.parentName) + ' ' + (item.requestPersonalInfo.parentSurname ?? ''),
          city: item.requestPersonalInfo.parentCity ? item.requestPersonalInfo.parentCity : '-',
          zone: item.requestPersonalInfo.parentZone ? item.requestPersonalInfo.parentZone : '-',
          category: item.requestPersonalInfo.requestCategory ? item.requestPersonalInfo.requestCategory : '-',
          date: item.operationalInfo.statusUpdated ?? item.timestamp,
          status: item.operationalInfo ? item.operationalInfo.status : '-',
          operator: item.requestPersonalInfo ? item.requestPersonalInfo.operator : '-',
          businessLine: item.requestPersonalInfo ? item.requestPersonalInfo.customer : '-'
        }
      });
    } else {
      this.original = []
    }
    this.richieste = this.original
  }

  private initiateValues() {
    this.filters = new UntypedFormGroup({
      id: new UntypedFormControl(),
      parent: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      phone: new UntypedFormControl(null),
      category: new UntypedFormControl('TUTTI'),
      babysitters: new UntypedFormControl(null),
      petsitters: new UntypedFormControl(null),
      city: new UntypedFormControl(null),
      zone: new UntypedFormControl(null),
      status: new UntypedFormControl('TUTTI'),
      operator: new UntypedFormControl(null),
      trash: new UntypedFormControl('false'),
      province: new UntypedFormControl(''),
      page: new UntypedFormControl(0),
      customerType: new UntypedFormControl('TUTTI'),
      serviceID: new UntypedFormControl(null),
    })

    this.filters.get('customerType')!.valueChanges.pipe(
      filter((value) => value !== 'TUTTI'),
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      const customerTypeValue = this.filters.get('customerType')!.value;
      const serviceIDValue = this.filters.get('serviceID')!.value;
      if (serviceIDValue !== null && customerTypeValue !== 'TUTTI') {
        this.filters.get('serviceID')!.setValue(null, { emitEvent: false });
      }
    })

    this.filters.get('serviceID')!.valueChanges.pipe(
      filter((value) => value !== null),
      takeUntil(this.unsubscribe)
    ).subscribe(() => {
      const customerTypeValue = this.filters.get('customerType')!.value;
      const serviceIDValue = this.filters.get('serviceID')!.value;
      if (serviceIDValue !== null && customerTypeValue !== 'TUTTI') {
        this.filters.get('customerType')!.setValue('TUTTI', { emitEvent: false });
      }
    })
  }

  setFormValue() {
    if (this.requestState !== undefined) {
      if (Object.keys(this.requestState.listFilters).length > 0) {
        this.filters.setValue({ ...this.requestState.listFilters })
      }
    }
  }

  updateFHService(service: FHService) {
    if (service) {
      this.filters.get('serviceID')?.setValue(service.id)
    } else {
      this.filters.get('serviceID')?.setValue(null);
    }
  }

  orderBy(field: string | null) {

    if (this.sortBy !== field) {
      this.sortDescControl.setValue(true)
    } else {
      this.sortDescControl.setValue(!this.sortDescControl.value)
    }
    this.sortBy = field;
  }

  updateOperator(newValue: string) {
    this.filters.get('operator')?.setValue(newValue);
    this.filters.markAllAsTouched();
    this.filters.get('operator')?.markAsDirty();
  }

  exportRequests() {
    this.requestService.exportRequestsFromDB()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'requests.xlsx';
        link.click();
      });
  }

  exportRequestUpdates() {
    this.requestService.exportRequestUpdates()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((blob) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'requests_updates.xlsx';
        link.click();
      });
  }

  onPageChange() {
    if (this.pageView > 0) {
      this.getRequestList()
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  // async convertAddress(array: Request[]) {
  //   for (let i = 0; i < array.length; i++) {
  //     const request = array[i];
  //     const address = request.requestPersonalInfo.parentAddress
  //     if (address !== null && address !== '') {
  //       try {
  //         const res = await this.googleMapsService.getCoordinates(request.requestPersonalInfo.parentAddress);
  //         const lat = res[0].geometry.location.lat();
  //         const lng = res[0].geometry.location.lng();

  //         const updatedRequest = {
  //           ...request,
  //           requestPersonalInfo: {
  //             ...request.requestPersonalInfo,
  //             latitude: lat,
  //             longitude: lng
  //           }
  //         };
  //         await this.requestService.updateRequest(updatedRequest).pipe(takeUntil(this.unsubscribe)).toPromise();

  //         // delay for each interaction
  //         await this.delay(2000);
  //       } catch (err) {
  //         console.log(err);
  //       }
  //     } else {
  //       console.log("UPDATED REQUEST ADDRESS NULL");
  //     }
  //   }
  // }

  // private delay(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
}
