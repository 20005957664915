<div class="d-flex flex-column" style="gap: 40px" [formGroup]="form">
  <fieldset>
    <legend>Gestione {{getCategoryLabel()}}</legend>
    <div class="my-5">
      <app-caregiver-selection
        [requestID]="requestID"
        [requestCategory]="requestCategory"
        >
      </app-caregiver-selection>
    </div>
  </fieldset>

  <fieldset>
    <legend>Stato richiesta</legend>
    <div class="fh-form-grid">
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="status">Stato</label>
        <select id="status" formControlName="status">
          <option value="NUOVA">NUOVA</option>
          <!-- <option value="PRESA IN CARICO">PRESA IN CARICO</option> -->
          <option value="INVIATO PREVENTIVO">INVIATO PREVENTIVO</option>
          <option value="PREVENTIVO ACCETTATO">PREVENTIVO ACCETTATO</option>
          <option value="SELEZIONE ATTIVA">SELEZIONE ATTIVA</option>
          <option value="INVIATO CV">INVIATO CV</option>
          <option value="PAGATO">PAGATO</option>
          <option value="SELEZIONE COMPLETATA">SELEZIONE COMPLETATA</option>
          <option value="STAND-BY">STAND-BY</option>
          <option value="NON RISPONDE">NON RISPONDE</option>
          <option value="DA RICHIAMARE">DA RICHIAMARE</option>
          <option value="NON PIU’ INTERESSATO">NON PIU’ INTERESSATO</option>
        </select>
      </div>
      <div class="fh-form-input-field">
        <label class="fw-semibold" for="profilogenitore">Profilo genitore</label>
        <input *ngIf="inputData.parentAttitude" type="text" id="profilogenitore" formControlName="parentAttitude"
          readonly>
        <button *ngIf="!inputData.parentAttitude" type="button" class="link-button" (click)="generateFormLink()">Genera
          link</button>
      </div>
      <div class="fh-form-input-field" *ngIf="form.get('status')?.value === 'DA RICHIAMARE'">
        <label class="fw-semibold">Promemoria</label>
        <input type="datetime-local" formControlName="reminder">
        <label class="fw-semibold" for="time">Quanto tempo prima?</label>
        <select [formControl]="time" name="time" id="time">
          <option value="600000">10 minuti</option>
          <option value="1200000">20 minuti</option>
          <option value="1800000">30 minuti</option>
          <option value="3600000">1 ora</option>
          <option value="7200000">2 ora</option>
        </select>
      </div>
      <div *ngIf="form.get('status')?.value === 'DA RICHIAMARE'" style="justify-self: start;">
        <p class="my-button secondary" [ngClass]="{'disabled': !form.get('reminder')?.valid}" (click)="setReminder()"
          style="position: relative;top: 0px;right: 0px;">Imposta</p>
      </div>
    </div>
  </fieldset>
  <div class="fh-form-input-field">
    <label style="grid-column: 1/3;" class="fw-semibold" for="notes">Note</label>
    <textarea class="w-100 mt-3" style="grid-column: 1/3;" rows="6" id="notes" maxlength="1024"
      formControlName="notes"></textarea>
  </div>
</div>
