<div id="modal" class="modal-container" (click)="dismiss($event)">
  <div class="modal-content">
    <form [formGroup]="filters">
      <div>
        <p class="category">Disponibilità</p>
        <div class="d-grid mb-3" style="grid-template-columns: 1fr 1fr">
          <div>
            <p class="category">Curare</p>
            <div class="checkbox-list mb-1" formGroupName="catHealing">
              <div>
                <input type="checkbox" id="cat-healing" formControlName="cat">
                <label for="cat-healing">Gatti</label>
              </div>
            </div>
            <div class="checkbox-list" formGroupName="dogHealing">
              <div>
                <input type="checkbox" id="dog-healing" formControlName="dog">
                <label for="dog-healing">Cani 0-7 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog1-healing" formControlName="dog1">
                <label for="dog1-healing">Cani 7-15 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog2-healing" formControlName="dog2">
                <label for="dog2-healing">Cani 15-30 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog3-healing" formControlName="dog3">
                <label for="dog3-healing">Cani 30-45 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog4-healing" formControlName="dog4">
                <label for="dog4-healing">Cani 45+ kg</label>
              </div>
            </div>
          </div>
          <div>
            <p class="category">Ospitare</p>
            <div class="checkbox-list mb-1" formGroupName="catHosting">
              <div>
                <input type="checkbox" id="cat-hosting" formControlName="cat">
                <label for="cat-hosting">Gatti</label>
              </div>
            </div>
            <div class="checkbox-list" formGroupName="dogHosting">
              <div>
                <input type="checkbox" id="dog-hosting" formControlName="dog">
                <label for="dog-hosting">Cani 0-7 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog1-hosting" formControlName="dog1">
                <label for="dog1-hosting">Cani 7-15 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog2-hosting" formControlName="dog2">
                <label for="dog2-hosting">Cani 15-30 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog3-hosting" formControlName="dog3">
                <label for="dog3-hosting">Cani 30-45 kg</label>
              </div>
              <div>
                <input type="checkbox" id="dog4-hosting" formControlName="dog4">
                <label for="dog4-hosting">Cani 45+ kg</label>
              </div>
            </div>
          </div>
        </div>
        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="drugs" formControlName="drugs">
            <label for="drugs">Somministrazione farmaci</label>
          </div>
          <div>
            <input type="checkbox" id="disability" formControlName="disability">
            <label for="disability">Esperienza con animali con disabilità psichiche</label>
          </div>
          <div>
            <input type="checkbox" id="illness" formControlName="illness">
            <label for="illness">Esperienza con animali con problemi comportamentali</label>
          </div>
          <div>
            <input type="checkbox" id="fhsub" formControlName="fhsub">
            <label for="fhsub">Iscrizione a Family+Happy</label>
          </div>
          <div>
            <input type="checkbox" id="homeService" formControlName="homeService">
            <label for="homeService">A domicilio</label>
          </div>
          <div>
            <input type="checkbox" id="hosting" formControlName="hosting">
            <label for="hosting">A casa del sitter</label>
          </div>
          <div>
            <input type="checkbox" id="walking" formControlName="walking">
            <label for="walking">Passeggiata cani</label>
          </div>
          <div>
            <input type="checkbox" id="nightShift" formControlName="nightShift">
            <label for="nightShift">Soggiorno notturno</label>
          </div>
          <div>
            <input type="checkbox" id="pension" formControlName="pension">
            <label for="pension">Pensione</label>
          </div>
          <div>
            <input type="checkbox" id="evaluation" formControlName="evaluation">
            <label for="evaluation">Voto generale maggiore o uguale a 6</label>
          </div>
        </div>
      </div>
      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="days">
          <p class="category">Giorni disponibili</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="sun" formControlName="sun">
              <label for="sun">Domenica</label>
            </div>
            <div>
              <input type="checkbox" id="mon" formControlName="mon">
              <label for="mon">Lunedì</label>
            </div>
            <div>
              <input type="checkbox" id="tue" formControlName="tue">
              <label for="tue">Martedì</label>
            </div>
            <div>
              <input type="checkbox" id="wed" formControlName="wed">
              <label for="wed">Mercoledì</label>
            </div>
            <div>
              <input type="checkbox" id="thu" formControlName="thu">
              <label for="thu">Giovedì</label>
            </div>
            <div>
              <input type="checkbox" id="fri" formControlName="fri">
              <label for="fri">Venerdì</label>
            </div>
            <div>
              <input type="checkbox" id="sat" formControlName="sat">
              <label for="sat">Sabato</label>
            </div>
          </div>
        </div>
        <div formGroupName="hours">
          <p class="category">Orari di disponibilità</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="mor" formControlName="mor">
              <label for="mor">Mattino</label>
            </div>
            <div>
              <input type="checkbox" id="aft" formControlName="aft">
              <label for="aft">Pomeriggio</label>
            </div>
            <div>
              <input type="checkbox" id="eve" formControlName="eve">
              <label for="eve">Sera</label>
            </div>
          </div>
        </div>
      </div>

      <div style="display: flex;flex-direction:column;gap:15px">
        <div formGroupName="exp">
          <p class="category">Esperienza</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="cluster1" formControlName="cluster1">
              <label for="cluster1">Con cuccioli</label>
            </div>
            <div>
              <input type="checkbox" id="cluster2" formControlName="cluster2">
              <label for="cluster2">Con adulti</label>
            </div>
            <div>
              <input type="checkbox" id="cluster3" formControlName="cluster3">
              <label for="cluster3">Con anziani</label>
            </div>
          </div>
        </div>
        <div formGroupName="contract">
          <p class="category">Interessi per incarichi</p>
          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="occ" formControlName="occ">
              <label for="occ">Occasionali (2 ore a settimana)</label>
            </div>
            <div>
              <input type="checkbox" id="part" formControlName="part">
              <label for="part">Part-time (4 ore a settimana)</label>
            </div>
            <div>
              <input type="checkbox" id="full" formControlName="full">
              <label for="full">Full-time</label>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="showAgeFilter">
        <p class="category">Età</p>
        <div style="display: flex; gap: 15px;">
          <input type="number" min="1" max="99" formControlName="minAge" placeholder="Da...">
          <input type="number" min="1" max="99" formControlName="maxAge" placeholder="A...">
        </div>
      </div>
      <div>
        <p class="category">Distanza massima in KM</p>
        <input type="number" id="distance" formControlName="distance" placeholder="fino a...">
      </div>
    </form>
    <div style="display: grid;grid-template-columns: repeat(3, 1fr);">
      <p id="cancel-filter" class="my-button secondary-button" (click)="resetFilters()">Reset</p>
      <p id="apply-filter" class="my-button" (click)="emitFilter()">Filtra</p>
    </div>
  </div>
</div>
