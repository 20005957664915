import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { catchError, finalize, switchMap, take, tap } from 'rxjs/operators';
import { ModalService } from 'npx-family-happy-common';
import { AuthService } from 'src/app/authentication/auth/auth.service';


@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  private annunciURL = `${environment.baseURL}/annunci`;
  private annunciKey = environment.annunciKey;
  private excludedUrl: string[] = ['/engagement-status']

  constructor(private authService: AuthService, private modalService: ModalService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getToken().pipe(take(1), switchMap((token) => {
      // Display loading modal
      this.excludedUrl.forEach((url) => {
        if(!req.url.includes(url)){
          this.modalService.showLoadingWithMessage('');
        }
      })
      let headers: HttpHeaders;

      if (req.url.match(this.annunciURL)) {
        headers = new HttpHeaders({
          'X-API-KEY': this.annunciKey,
          'Accept': 'application/json, text/plain, */*'
        })
      } else if (token && token.token !== '' && !this.authService.isTokenExpired(token)) {
        headers = new HttpHeaders({
          'Authorization': 'Bearer ' + token.token,
          'Accept': 'application/json, text/plain, */*'
        })
      } else {
        headers = new HttpHeaders({
        })
        console.log('Token expired.')
        //this.router.navigate(['/login']);
      }

      req = req.clone({ headers });

      if (!environment.production)
        console.log(req);

      return next.handle(req).pipe(
        catchError((error: HttpErrorResponse) => {
          if (!environment.production)
            console.log('HttpErrorResponse: ', error);
          if (error.status == 500) {
            if ((!(req.url.match('babysitter-suitable') || !(req.url.match('request-payment'))) && req.method === 'DELETE'))
              this.showErrorToast(`Sembra si sia verificato un problema sul server,
      si prega di riprovare. Se l'errore dovesse persistere, contatta l'amministratore
      della piattaforma.`, '500 - ERRORE SERVER');
          } else if (error.status == 401) {
            this.showErrorToast('Il token potrebbe essere scaduto. Effettua nuovamente il login.', '401 - NON AUTORIZZATO');
          } else if (error.status == 403) {
            this.showErrorToast(`Non sei autorizzato ad accedere a queste risorse.
      Se pensi ci sia un errore, contatta l'amministratore della piattaforma.`, '403 - ACCESSO NEGATO');
          } else if (error.status == 404) {
            if (!req.url.match('familyhappy.xyz/retrieve-by-id') && !req.url.match('familyhappy.xyz/v1/scheduled-appointments') && !req.url.match('familyhappy.xyz/v1/referral-usage'))
              this.showErrorToast(`Non è stato possibile trovare la risorsa indicata. Potrebbe non essere più presente in database.`, '404 - RISORSA NON TROVATA');
          } else {
            this.showErrorToast(error.error?.message, `Si è verificato un errore generico - ${error.status.toString()}`);
          }

          return throwError(error);
        }),
        finalize(() => {
          // Hide loading modal
          this.modalService.hideLoading();
        }),
      ) as Observable<HttpEvent<any>>;
    }))
  }

  private showErrorToast(message: string, title: string) {
    this.modalService.showErrorMessage(`${title} - ${message}`);
  }

}
