<div class="bigger-container">
  <div class="left-section">
    <p [ngClass]="{'selected': step === 0}" (click)="changeTab(0)">Anagrafica</p>
    <p [ngClass]="{'selected': step === 1}" (click)="changeTab(1)">Esperienza</p>
    <p [ngClass]="{'selected': step === 2}" (click)="changeTab(2)">Referenze</p>
    <p [ngClass]="{'selected': step === 3}" (click)="changeTab(3)">Formazione</p>
    <p [ngClass]="{'selected': step === 4}" (click)="changeTab(4)">Disponibilità</p>
    <p [ngClass]="{'selected': step === 5}" (click)="changeTab(5)">Valutazione</p>
    <p [ngClass]="{'selected': step === 6}" (click)="changeTab(6)">Coinvolgimento</p>
  </div>
  <div class="right-section">
    <div class="back-button" (click)="goBack()">
      <i class="material-icons">arrow_back</i>
      <p>Torna alla lista</p>
    </div>
    <div class="smaller-container">
      <p class="smaller-text">{{isNew ? 'Crea' : 'Modifica'}} Petsitter</p>
      <div class="smaller-bar"></div>
    </div>
    <form [formGroup]="form" class="mt-5 px-3">
      <div *ngIf="step === 0" class="grid" formGroupName="info">
        <div>
          <label class="label" for="name">Nome<span class="red">*</span></label>
          <input type="text" id="name" formControlName="name">
        </div>
        <div>
          <label class="label" for="surname">Cognome<span class="red">*</span></label>
          <input type="text" id="surname" formControlName="surname">
        </div>
        <div>
          <label class="label" for="email">Email<span class="red">*</span></label>
          <input type="text" id="email" formControlName="email">
        </div>
        <div>
          <label class="label" for="phone">Cellulare<span class="red">*</span></label>
          <input type="text" id="phone" formControlName="phone">
        </div>
        <div>
          <label class="label" for="address">Indirizzo</label>
          <input type="text" id="address" formControlName="address">
        </div>
        <div>
          <label class="label" for="city">Città</label>
          <input type="text" id="city" formControlName="city">
        </div>
        <div>
          <label class="label" for="zone">Zona</label>
          <input type="text" id="zone" formControlName="zone">
        </div>
        <div>
          <label class="label" for="cap">CAP</label>
          <input type="text" id="cap" formControlName="cap">
        </div>
        <div>
          <label class="label" for="age">Età</label>
          <input type="number" id="age" formControlName="age" min="0" max="99">
        </div>
        <div>
          <label class="label" for="birthDate">Data di Nascita</label>
          <input type="date" id="birthDate" formControlName="birthDate">
        </div>
        <div>
          <label class="label" for="provincia">Provincia di interesse</label>
          <app-province-filter [provincia]="form.get('info.provincia')?.value" (valueChange)="updateProvincia($event)"></app-province-filter>
        </div>
        <div>
          <label class="label" for="fiscalcode">Codice Fiscale</label>
          <input type="text" id="fiscalcode" style="text-transform: uppercase;" formControlName="fiscalcode">
        </div>
        <div>
          <label class="label" for="iban">IBAN</label>
          <input type="text" id="iban" formControlName="iban">
        </div>
        <div>
          <label class="label">Video</label>
          <input type="text" formControlName="video">
        </div>
        <div>
          <label class="label">Certificato Sicurezza</label>
          <div>
            <a [href]="petsitter && petsitter.certificate"
              *ngIf="petsitter && petsitter.certificate && petsitter.certificate !== ''" target="_blank" class="tertiary-button">Scarica</a>
            <p *ngIf="!petsitter || !petsitter.certificate || petsitter.certificate === ''" style="grid-column: 1/3">La petsitter non ha
              ancora ottenuto la certificazione.</p>
          </div>
        </div>
        <div>
          <label class="label">CV Family Happy</label>
          <div style="grid-template-columns: repeat(3, 1fr)">
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button" (click)="generateCV(true)">Crea</button>
            </div>
            <a *ngIf="petsitter ? (petsitter.info ? petsitter.info.resumeHidden : false) : false"
              class="tertiary-button" download
              [href]="petsitter ? (petsitter.info ? petsitter.info.resumeHidden : '') : ''" target="_blank">Scarica</a>
            <a *ngIf="petsitter ? (petsitter.info ? petsitter.info.resumeHidden : false) : false"
              class="tertiary-button" (click)="cvPreview()"
              [ngStyle]="{ 'opacity': generatePages ? '50%' : '100%' }">Invia</a>
          </div>
        </div>
        <div>
          <label class="label">Documenti</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input type="file" accept=".pdf" (change)="uploadFile($event, 'info.resume')">
            </div>
            <a *ngIf="petsitter ? (petsitter.info ? petsitter.info.resume : false) : false" class="tertiary-button"
              download [href]="petsitter ? (petsitter.info ? petsitter.info.resume : '') : ''"
              target="_blank">Scarica</a>
          </div>
        </div>
        <div *ngIf="!isNew; else elseBlock">
          <label class="label">Foto</label>
          <div>
            <div class="upload-button-container" style="display: block;">
              <button class="secondary-button">Carica</button>
              <input id="image-selection" type="file" accept="image/*" (change)="openModal($event)">
            </div>
            <a *ngIf="petsitter ? (petsitter.info ? petsitter.info.photo : false) : false" class="tertiary-button"
              download [href]="petsitter ? (petsitter.info ? petsitter.info.photo : '') : ''"
              target="_blank">Scarica</a>
          </div>
        </div>
        <ng-template #elseBlock>
          <div>
            <label class="label">Foto</label>
            <p id="foto">Puoi aggiungere la foto nella sezione "modifica" del profilo</p>
          </div>
        </ng-template>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="stopWhatsapp">Stop Whatsapp</label>
          <input type="checkbox" id="stopWhatsapp" formControlName="stopWhatsapp">
        </div>
        <div class="input-field">
          <label for="subscription" class="label">Stato Abbonamento</label>
          <input type="text" formControlName="subscription" id="subscription" readonly>
        </div>
        <div class="input-field">
          <label class="label" for="expDate">Scadenza Abbonamento</label>
          <input type="date" id="expDate" formControlName="expDate" readonly>
        </div>
        <div class="input-field">
          <label for="manualSubscription" class="label">Stato Abbonamento Manuale</label>
          <select formControlName="manualSubscription" id="manualSubscription">
            <option value="">Scegli un'opzione</option>
            <option value="ABBONATO">ABBONATO</option>
            <option value="EXABBONATO">EX ABBONATO</option>
          </select>
        </div>
        <div class="input-field">
          <label class="label" for="expDateManual">Scadenza Abbonamento Manuale</label>
          <input type="date" id="expDateManual" formControlName="expDateManual">
        </div>
        <div *ngIf="petsitter !== undefined">
          <label class="label" for="subInterest">ID Petsitter:</label>
          <p style="font-weight: bold;">{{petsitter.docRef}}</p>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="description">Descrizione</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="description" maxlength="426"
            formControlName="description"></textarea>
        </div>
        <div style="grid-column: 1/3;" class="input-field">
          <label class="label" for="note">Note</label>
          <textarea style="grid-column: 1/3;width: 100%;" rows="6" id="note" maxlength="2048"
            formControlName="notes"></textarea>
        </div>
      </div>
      <div *ngIf="step === 1" class="grid" style="grid-template-columns: 1fr 1fr; gap: 2em;" formGroupName="exp">
        <div formGroupName="cluster1"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster1Age">Anni di esperienza con Cuccioli</label>
            <input type="number" id="cluster1Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster1.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster1Employer">Presso</label>
              <select id="cluster1Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Struttura">Struttura</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="peeing">Insegnare a fare i bisogni all'esterno</label>
                <input type="checkbox" id="peeing" formControlName="peeing" name="peeing">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="games">Giochi educativi (specificare)</label>
                <input type="checkbox" id="games" formControlName="games" name="games">
              </div>
              <div class="input-field">
                <input type="text" formControlName="game" id="game">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="social">Socializzazione</label>
                <input type="checkbox" id="social" formControlName="social" name="social">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="muzzle">Utilizzo della museruola</label>
                <input type="checkbox" id="muzzle" formControlName="muzzle" name="muzzle">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="other">Altri ausili</label>
                <input type="checkbox" id="other" formControlName="other" name="other">
              </div>
              <div class="input-field">
                <input type="text" formControlName="help" id="help1">
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="cluster2"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster2Age">Anni di esperienza con Adulti</label>
            <input type="number" id="cluster2Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster2.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster2Employer">Presso</label>
              <select id="cluster2Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Struttura">Struttura</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="interactions">Interazione con altri cani/gatti</label>
                <input type="checkbox" id="interactions" formControlName="interactions" name="interactions">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="walking">Passeggiate esterne</label>
                <input type="checkbox" id="walking" formControlName="walking" name="walking">
              </div>
            </div>
          </div>
        </div>
        <div formGroupName="cluster3"
          style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
          <div>
            <label class="label label-highlight" for="cluster3Age">Anni di esperienza con Anziani</label>
            <input type="number" id="cluster3Age" min=0 formControlName="expYears">
          </div>
          <div *ngIf="form.get('exp.cluster3.expYears')?.value"
            style="display: flex; flex-direction: column; gap: 10px;">
            <div>
              <label class="label" for="cluster3Employer">Presso</label>
              <select id="cluster3Employer" formControlName="employer">
                <option value="Famiglia privata">Famiglia privata</option>
                <option value="Struttura">Struttura</option>
              </select>
            </div>
            <div formGroupName="tasks" style="display: flex; flex-direction: column; gap: 10px;">
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="helpers">Utilizzo ausili di movimento (specificare)</label>
                <input type="checkbox" id="helpers" formControlName="helpers" name="helpers">
              </div>
              <div class="input-field">
                <input type="text" formControlName="help" id="help2">
              </div>
              <div style="display: flex; justify-content: space-between;">
                <label class="label" for="diseases">Conoscere le eventuali patologie degli animali e gestirle</label>
                <input type="checkbox" id="diseases" formControlName="diseases" name="diseases">
              </div>
              <!-- TODO: Adattare per le cose da (specificare) -->
              <!-- <div *ngIf="form.get('exp.cluster3.tasks.creativeGames')?.value"
                style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getFormArrayControls(3, 'gamesDesc'), index as i">
                  <label class="label" for="game-{{ i + 1 }}">Gioco {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="game-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button"
                    (click)="changeFormArrayLength(true, 3, 'gamesDesc')">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('exp.cluster3.gamesDesc')?.value.length > 1"
                    (click)="changeFormArrayLength(false, 3, 'gamesDesc')">-</button>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div></div>

        <div>
          <label class="label label-highlight" for="totalExp">Anni di esperienza complessivi</label>
          <input type="number" id="totalExp" min=0 formControlName="total">
        </div>

        <div>
          <button type="button" class="secondary-button" (click)="computeTotalExp()">Calcolo automatico</button>
        </div>
      </div>
      <div *ngIf="step === 2" class="grid" formGroupName="reference">
        <div>
          <label class="label" for="f_surname">Cognome prima famiglia</label>
          <input type="text" id="f_surname" formControlName="f_surname">
        </div>
        <div>
          <label class="label" for="f_second_surname">Cognome seconda famiglia</label>
          <input type="text" id="f_second_surname" formControlName="f_second_surname">
        </div>
      </div>
      <div *ngIf="step === 3" formGroupName="lang">
        <div class="grid">
          <div>
            <label class="label" for="title">Titolo di studio</label>
            <select formControlName="title">
              <option value="Medie inferiori">Medie inferiori</option>
              <option value="Medie superiori">Medie superiori</option>
              <option value="Laurea">Laurea</option>
            </select>
          </div>
          <div [ngStyle]="{'opacity': form.get('lang.title')?.value !== 'Laurea' ? '70%' : '100%'}">
            <label class="label" for="graduationType">Tipo di laurea</label>
            <input type="text" id="graduationType" formControlName="graduationType" />
          </div>
          <div class="input-field" style="grid-template-columns: 1fr 20px;">
            <label class="label" for="drugs">Somministrazione farmaci</label>
            <input type="checkbox" id="drugs" formControlName="drugs">
            <label class="label" for="disability">Esperienza con animali con disabilità/psichiche</label>
            <input type="checkbox" id="disability" formControlName="disability">
            <label class="label" for="mentalIllness">Esperienza con animali con problemi comportamentali</label>
            <input type="checkbox" id="mentalIllness" formControlName="mentalIllness">
          </div>
          <fieldset style="width: 100%;">
            <legend>Certificati</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <div style="display: flex; flex-direction: column; gap: 10px;">
                <div *ngFor="let formControl of getCertificatesFormControls(), index as i">
                  <label class="label" for="otherCert-{{ i + 1 }}">Certificato {{ i + 1 }}</label>
                  <input type="text" [formControl]="$any(formControl)" id="otherCert-{{ i + 1 }}" />
                </div>
                <div style="display: flex; gap: 15px; align-items: flex-end;">
                  <button class="quantity-button secondary-button" (click)="changeCertificatesLength(true)">+</button>
                  <button class="quantity-button secondary-button"
                    *ngIf="form.get('lang.certificates')?.value.length > 1"
                    (click)="changeCertificatesLength(false)">-</button>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="mt-5">
          <label class="label pe-4" for="courses">Corsi di formazione: </label>
          <button class="btn btn-secondary" style="max-width: 40%; color:white" id="courses" (click)="sendCoursesWhatsapp()">Invia corsi Whatsapp</button>
        </div>
      </div>
      <div *ngIf="step === 4" class="grid" formGroupName="avail">
        <div style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;" formGroupName="catHealing">
          <fieldset style="width: 100%;">
            <legend>Posso curare (Gatti)</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="cat">Gatti</label>
              <input type="checkbox" id="cat" formControlName="cat">
            </div>
          </fieldset>
        </div>
        <div style="grid-template-columns: unset; grid-column: 2/2; margin-top: 20px;" formGroupName="catHosting">
          <fieldset style="width: 100%;">
            <legend>Posso ospitare (Gatti)</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="catHosting.cat">Gatti</label>
              <input type="checkbox" id="catHosting.cat" formControlName="cat">
            </div>
          </fieldset>
        </div>
        <div style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;" formGroupName="dogHealing">
          <fieldset style="width: 100%;">
            <legend>Posso curare (Cani)</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="dog">0-7 kg</label>
              <input type="checkbox" id="dog" formControlName="dog">
              <label class="label" for="dog1">7-15 kg</label>
              <input type="checkbox" id="dog1" formControlName="dog1">
              <label class="label" for="dog2">15-30 kg</label>
              <input type="checkbox" id="dog2" formControlName="dog2">
              <label class="label" for="dog3">30-45 kg</label>
              <input type="checkbox" id="dog3" formControlName="dog3">
              <label class="label" for="dog4">45+ kg</label>
              <input type="checkbox" id="dog4" formControlName="dog4">
            </div>
          </fieldset>
        </div>
        <div style="grid-template-columns: unset; grid-column: 2/2; margin-top: 20px;" formGroupName="dogHosting">
          <fieldset style="width: 100%;">
            <legend>Posso ospitare (Cani)</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="dogHosting.dog">0-7 kg</label>
              <input type="checkbox" id="dogHosting.dog" formControlName="dog">
              <label class="label" for="dogHosting.dog1">7-15 kg</label>
              <input type="checkbox" id="dogHosting.dog1" formControlName="dog1">
              <label class="label" for="dogHosting.dog2">15-30 kg</label>
              <input type="checkbox" id="dogHosting.dog2" formControlName="dog2">
              <label class="label" for="dogHosting.dog3">30-45 kg</label>
              <input type="checkbox" id="dogHosting.dog3" formControlName="dog3">
              <label class="label" for="dogHosting.dog4">45+ kg</label>
              <input type="checkbox" id="dogHosting.dog4" formControlName="dog4">
            </div>
          </fieldset>
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;margin-top: 20px;">
          <label class="label" for="hosting">Pet sitting a casa del sitter</label>
          <input type="checkbox" id="hosting" formControlName="hosting">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;margin-top: 20px;">
          <label class="label" for="homeService">Pet sitting a domicilio</label>
          <input type="checkbox" id="homeService" formControlName="homeService">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="grooming">Toelettatura</label>
          <input type="checkbox" id="grooming" formControlName="grooming">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="walking">Passeggiata cani</label>
          <input type="checkbox" id="walking" formControlName="walking">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="pension">Pensione</label>
          <input type="checkbox" id="pension" formControlName="pension">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="nightShift">Soggiorno notturno</label>
          <input type="checkbox" id="nightShift" formControlName="nightShift">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="veterinarian">Accompagnamento a visite veterinarie</label>
          <input type="checkbox" id="veterinarian" formControlName="veterinarian">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="cleaning">Pulizia lettiera</label>
          <input type="checkbox" id="cleaning" formControlName="cleaning">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="coliving">Disp. Convivenza</label>
          <input type="checkbox" id="coliving" formControlName="coliving">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="relocation">Disp. Trasferte</label>
          <input type="checkbox" id="relocation" formControlName="relocation">
        </div>
        <div></div>
        <div></div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="license">Patente</label>
          <input type="checkbox" id="license" formControlName="license">
        </div>
        <div class="input-field" style="grid-template-columns: 1fr 20px;">
          <label class="label" for="car">Automunita</label>
          <input type="checkbox" id="car" formControlName="car">
        </div>
        <div style="grid-template-columns: unset; grid-column: 1/2; margin-top: 20px;" formGroupName="days">
          <fieldset style="width: 100%;">
            <legend>Giorni disponibili</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="sun">Domenica</label>
              <input type="checkbox" id="sun" formControlName="sun">
              <label class="label" for="mon">Lunedì</label>
              <input type="checkbox" id="mon" formControlName="mon">
              <label class="label" for="tue">Martedì</label>
              <input type="checkbox" id="tue" formControlName="tue">
              <label class="label" for="wed">Mercoledì</label>
              <input type="checkbox" id="wed" formControlName="wed">
              <label class="label" for="thu">Giovedì</label>
              <input type="checkbox" id="thu" formControlName="thu">
              <label class="label" for="fri">Venerdì</label>
              <input type="checkbox" id="fri" formControlName="fri">
              <label class="label" for="sat">Sabato</label>
              <input type="checkbox" id="sat" formControlName="sat">
            </div>
          </fieldset>
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;margin-top: 20px;">
          <fieldset style="width: 100%;" formGroupName="hours">
            <legend>Orari di disponibilità</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="mor">Mattino</label>
              <input type="checkbox" id="mor" formControlName="mor">
              <label class="label" for="aft">Pomeriggio</label>
              <input type="checkbox" id="aft" formControlName="aft">
              <label class="label" for="eve">Sera</label>
              <input type="checkbox" id="eve" formControlName="eve">
            </div>
          </fieldset>
          <fieldset style="width: 100%;" formGroupName="contract">
            <legend>Interessi per incarichi</legend>
            <div class="input-field" style="grid-template-columns: 1fr 20px;">
              <label class="label" for="occ">Occasionali (2 ore a settimana)</label>
              <input type="checkbox" id="occ" formControlName="occ">
              <label class="label" for="part">Part-time (4 ore a settimana)</label>
              <input type="checkbox" id="part" formControlName="part">
              <label class="label" for="full">Full-time</label>
              <input type="checkbox" id="full" formControlName="full">
            </div>
          </fieldset>
        </div>
        <div style="display: flex;flex-direction: column;align-items: start;margin-top: 20px;">
          <fieldset style="width: 100%;">
            <legend>Tariffa netta indicativa</legend>
            <lib-virtual-scroll [label]="'tariffa minima'" [values]="100" [formCtrl]="minWage | formControl"></lib-virtual-scroll>
            <lib-virtual-scroll [label]="'tariffa massima'" [values]="100" [formCtrl]="maxWage | formControl"></lib-virtual-scroll>
          </fieldset>
        </div>
      </div>
      <app-caregiver-evaluation *ngIf="step === 5" [docRef]="petsitterID" [rating]="petsitter !== undefined ? petsitter.rating : defaultRating" (dataUpdated)="onCaregiverEvaluationChanged($event)"></app-caregiver-evaluation>
      <app-caregiver-engagement *ngIf="step === 6" [engagement]="petsitter !== undefined ? petsitter.engagement : defaultEngagement" [docRef]="petsitterID"></app-caregiver-engagement>
    </form>
    <div class="buttons-container">
      <button *ngIf="form.valid && form.touched && form.dirty" class="my-button" id="save-button"
        (click)="save(true)">Salva</button>
      <button *ngIf="!(form.valid && form.touched && form.dirty)" class="my-button disabled" id="save-button-disabled"
        disabled>Salva</button>
    </div>
  </div>
</div>

<app-alert *ngIf="showAlert" (save)="onAlertAction($event)" [data]="{ type: undefined }"
  [title]="'Attenzione, alcuni dati sono stati modificati!'" [message]="'Vuoi salvare le modifiche effettuate?'">
</app-alert>

<div class="modal" id="modal-container" *ngIf="showModal" (click)="dismiss($event)">
  <div class="modal-content">
    <app-image-cropper [imageSource]="sanitize(chosenPhoto)" (outputEvent)="getCroppedPhoto($event)">
    </app-image-cropper>
    <div class="modal-button-container">
      <button class="secondary-button" (click)="cropperAbort()">Annulla</button>
      <button class="secondary-button" (click)="uploadImage(finalPhoto, petsitter.docRef)">Conferma</button>
    </div>
  </div>
</div>


<app-cv-petsitter-template [petsitter]="petsitter" [generatePages]="generatePages" [currentPage]="currentPage"
  (sendPages)="cvPages = $event" style="position:absolute; z-index: -5; left: -1000px;"></app-cv-petsitter-template>
