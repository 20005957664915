import { TimerPipe } from './../../pipes/timer.pipe';
import { ProvinceService } from './../../services/province-service/province.service';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/authentication/auth/auth.service';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { User } from '../../models/user.model';
import { UserManagementService } from 'src/app/backoffice/user-management/user-management-service/user-management.service';
import { CommonModule } from '@angular/common';
import { ModalService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { AlertComponent } from '../modals/alert/alert.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadBalanceService } from 'src/app/backoffice/load-balance/load-balance-service/load-balance.service';
import { catchError, filter, finalize, map, of, Subject, Subscription, switchMap, takeUntil, takeWhile, tap, throwError, timer } from 'rxjs';
import { ToastService } from '../../services/toast-service/toast.service';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, ProvinceFilterComponent, AlertComponent, NgbDropdownModule, TimerPipe]
})
export class HeaderBarComponent implements OnInit, OnDestroy {
  @Output() showMenu = new EventEmitter();
  user!: User;
  showAlert: boolean = false;
  editForm: UntypedFormGroup;
  provincia: string | null = null;

  isTutor:boolean = false;
  isBoostActive: boolean = false;
  cooldown!: number | null;
  timerSubscription: Subscription | null = null;

  unsubscribe = new Subject<void>();

  constructor(
    private userStorage: UserStorageService,
    private router: Router,
    private auth: AuthService,
    private management: UserManagementService,
    private provinceService: ProvinceService,
    private loadBalanceService: LoadBalanceService,
    private modalService: ModalService,
    private toastService: ToastService
  ) {
    this.editForm = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      surname: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('nameSurname')
    });
  }

  ngOnInit(): void {
    this.provincia = this.provinceService.getProvince().getValue();

    this.userStorage.getUser().subscribe((user) => {
      if (user) {
        this.user = user;
        this.editForm.patchValue({ name: this.user?.name, surname: this.user?.surname })
      }
    });

    this.loadBalanceService.isTutorCheck(this.user.uid).pipe(
      filter((isTutor) => isTutor.check !== 'false'),
      map(() => this.isTutor = true ),
      switchMap(() => this.loadBalanceService.getReaminingFactorTime(this.user.uid)),
      map((res) => parseInt(res.remainingFactorTime, 10)),
      tap((remainingTime) => {
        if (isNaN(remainingTime) || remainingTime <= 0) {
          throw new Error("Timer terminato o valore non valido");
        }
      }),
      tap((remainingTime) => {
        this.isBoostActive = true
        this.isBoostActive = true;
        this.loadBalanceService.setTimer(remainingTime);
        this.startTimer(remainingTime);
      }),
      catchError((err: any) => {
        return of(null);
      }
      )).subscribe()
  }

  startTimer(time: number) {
    this.timerSubscription = timer(0, 1000).pipe(
      map((seconds: number) => time - seconds),
      takeWhile((remainingTime: number) => remainingTime > 0),
      tap((remainingTime: number) => {
        this.cooldown = remainingTime
        this.loadBalanceService.setTimer(remainingTime)
      }),
      finalize(() => this.handleBoostEnd())
    ).subscribe()
  }

  handleBoostEnd() {
    this.loadBalanceService.deactivateBoost(this.user.uid).subscribe({
      next: () => {
        this.loadBalanceService.setTimer(null);
        this.isBoostActive = false
        this.cooldown = 0
        this.toastService.setAction('Boost terminato! Non riceverai più richieste in eccesso.', false);
      },
      error: (err: any) => {
        console.log('Errore durante la disattivazione del Boost:', err)
      }
    })
  }

  signOut() {
    this.auth.signOut().then(() => {
      this.router.navigate(['/login']);
      console.log('Signed out');
    })
  }

  editNameSurname() {
    this.showAlert = true;
  }

  closeEditModal() {
    this.showAlert = false;
  }

  updateProvincia(newValue: string) {
    this.provinceService.setProvince(newValue);
  }

  onAlertAction(save: boolean) {
    if (save) {
      const name = this.editForm.value.name
      const surname = this.editForm.value.surname
      this.userStorage.setUser(this.user as User)
      this.management.changeUserName(this.user.uid, name, surname).subscribe((user) => {
        this.closeEditModal()
      });
    }
    else this.closeEditModal()
  }

  activeBoost(event: any) {
    const target = event.target as HTMLInputElement;
    this.isBoostActive = target.checked;
    if (this.isBoostActive) {
      this.loadBalanceService.isTutorCheck(this.user.uid).pipe(
        switchMap((isTutor) => {
          if(isTutor.check === 'false'){
            this.isBoostActive = false
            return throwError(() => new Error('Utente non autorizzato a utilizzare il boost'));
          }
          return this.loadBalanceService.activeBoost(this.user.uid, 100).pipe(
            tap(() => {
              this.isBoostActive = true;
              this.toastService.setAction("Il boost è da ora attivo per un'ora. In quest'ora, avrai molte più probabilità di ottenere una richiesta da gestire.", true)
            }),
            switchMap(() => {
              return this.loadBalanceService.getReaminingFactorTime(this.user.uid).pipe(
                switchMap((res) => {
                  const remainingTime = parseInt(res.remainingFactorTime, 10);
                  if (isNaN(remainingTime) || remainingTime <= 0) {
                    return throwError(() => new Error("Timer terminato o valore non valido"));
                  }
                  return of(remainingTime);
                }),
                tap((remainingTime) => {
                  this.isBoostActive = true;
                  this.loadBalanceService.setTimer(remainingTime);
                  this.startTimer(remainingTime);
                }),
              )
            }
          ))
        }
      ),
        catchError((err: any) => {
          this.isBoostActive = false
          this.modalService.showErrorMessage(err.message || "Errore imprevisto durante l'attivazione del boost.");
          return of(null)
        }),
        takeUntil(this.unsubscribe),
      ).subscribe()
    } else {
      this.loadBalanceService.deactivateBoost(this.user.uid).pipe(
        tap(() => {
          this.isBoostActive = false;
          this.loadBalanceService.setTimer(null);
          if(this.timerSubscription) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = null
          }
        }),
        takeUntil(this.unsubscribe)
      ).subscribe()
  }
}

    ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
    }
  }


