import { familyMode, PricingFamilySwitchComponent } from './mode-switch-pricing/pricing-family-switch.component';
import { caregiverMode, PricingCaregiverSwitchComponent } from './mode-switch-pricing/pricing-switch.component';
import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { CaregiverPricing, ColfRequestDetail, FHService, ProductType, RequestCategory, RequestDetail, RequestNurseDetail, RequestPetsitterDetail, RequestUtilitiesService } from "npx-family-happy-common";
import { Subject, takeUntil } from 'rxjs';
import { ServiceServices } from 'src/app/backoffice/services/service-services/service.services';
import { AlertComponent } from 'src/app/shared/components/modals/alert/alert.component';

@Component({
  selector: 'app-request-pricing',
  templateUrl: './request-pricing.component.html',
  imports: [CommonModule, ReactiveFormsModule, PricingCaregiverSwitchComponent, PricingFamilySwitchComponent, AlertComponent],
  standalone: true,
  styleUrls: ['./request-pricing.component.scss']
})
export class RequestPricingComponent implements OnInit, OnDestroy {

  @Input() province!: string;
  @Input() request!: RequestDetail | ColfRequestDetail | RequestNurseDetail | RequestPetsitterDetail;
  @Input() reqService!: FHService;

  private _moreThanOne!: boolean;
  @Input()
  set moreThanOneInput(value: boolean) {
    this._moreThanOne = value;
    this.form.get('moreThanOne')!.setValue(value);
  }

  private _handicap!: boolean
  @Input()
  set handicapInput(value: boolean) {
    this._handicap = value;
    this.form.get('handicap')?.setValue(value);
  }

  private _badWorkHours!: boolean
  @Input()
  set badWorkHoursInput(value: boolean) {
    this._badWorkHours = value;
    this.form.get('badWorkHours')?.setValue(value);
  }

  private _continuousJob!: boolean
  @Input()
  set continuousJobInput(value: boolean) {
    this._continuousJob = value;
    this.form.get('continuousJob')?.setValue(value);
  }

  private _inconvenientLocation!: boolean
  @Input()
  set inconvenientLocationInput(value: boolean) {
    this._inconvenientLocation = value;
    this.form.get('inconvenientLocation')?.setValue(value);
  }

  @Output() pricing = new EventEmitter<any>();


  familyPricingMode!: familyMode;
  localMode!: caregiverMode;
  caregiverPricingMode!: caregiverMode;
  private unsubscribe = new Subject<void>();

  periodFlatCost!: string | null;
  recapMessageCaregiver!: string | null;
  costoPacchettoOre!: string | null;
  servicePrice!: string | null;

  alertMessage!: string
  showAlert: boolean = false

  form = new FormGroup({
    taxisitter: new FormControl(false),
    hoursAmount: new FormControl(0),
    netHourCost: new FormControl(0),
    flatCost: new FormControl(0),
    flatCostTiming: new FormControl('0'),
    packageFlatCost: new FormControl(0),
    withholdingTax: new FormControl(false),
    moreThanOne: new FormControl(false),
    handicap: new FormControl(false),
    badWorkHours: new FormControl(false),
    continuousJob: new FormControl(false),
    inconvenientLocation: new FormControl(false)
  });

  constructor(private utilities: RequestUtilitiesService, private servicesService: ServiceServices) { }

  ngOnInit(): void {
    if (this.request !== undefined) {
      this.initForm();
      this.initSwitchCaregiverMode();
      this.initSwitchFamilyMode();
      this.recapMessageCaregiver = this.generateCaregiverPricingMessage()
      // this.periodFlatCost = this.convertFlatTimingToString(this.request.flatCostTiming)
    }
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      this.pricing.emit(value!)
      this.recapMessageCaregiver = this.generateCaregiverPricingMessage()
      this.costoPacchettoOre = this.utilities.computeHoursPackagePrice(this.province, this.moreThanOne, this.handicap, this.badWorkHours, this.hoursAmount, this.netHourCost).toFixed(2);
    })
    this.servicesService.getServiceOverview(this.reqService.id, this.province).pipe(takeUntil(this.unsubscribe)).subscribe((overview) => {
      const price = overview.products.find(item => item.productType === ProductType.SERVICE)?.amount;
      const subscription = overview.products.find(item => item.productType !== ProductType.SERVICE)?.amount;
      if (price && subscription) this.servicePrice = ((price + subscription) / 100).toFixed(2)
    })
  }

  // Getter per i singoli FormControl
  get taxisitter() {
    return this.form.get('taxisitter')!.value;
  }

  get badWorkHours() {
    const control = this.form.get('badWorkHours');
    return control?.value ?? false;
  }

  get continuousJob() {
    const control = this.form.get('continuousJob');
    return control?.value ?? false;
  }

  get inconvenientLocation() {
    const control = this.form.get('inconvenientLocation');
    return control?.value ?? false;
  }

  get hoursAmount() {
    const control = this.form.get('hoursAmount');
    return control?.value ?? 0;
  }

  get netHourCost() {
    const control = this.form.get('netHourCost');
    return control?.value ?? 0;
  }

  get flatCost() {
    return this.form.get('flatCost')!.value;
  }

  get flatCostTiming() {
    return this.form.get('flatCostTiming')!.value;
  }

  get packageFlatCost() {
    return this.form.get('packageFlatCost')!.value;
  }

  get withholdingTax() {
    return this.form.get('withholdingTax')!.value;
  }

  get moreThanOne() {
    const control = this.form.get('moreThanOne');
    return control?.value ?? false;
  }

  get handicap() {
    const control = this.form.get('handicap');
    return control?.value ?? false;
  }


  updateNetHourPrice() {
    const caregiverPricing: CaregiverPricing = {
      provincia: this.province,
      moreThanOne: this.moreThanOne,
      handicap: this.handicap,
      badWorkHours: this.badWorkHours,
      netHourCost: null,
      businessLine: this.reqService.businessLine,
      continousJob: this.continuousJob,
      inconvenientLocation: this.inconvenientLocation
    };
    this.form.get('netHourCost')?.setValue(
      this.utilities.computeNetPriceV2(caregiverPricing));
  }

  decreaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value && value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value && value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  decreaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value && value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value && value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  switchCaregiverMode(mode: caregiverMode) {
    this.localMode = mode
    this.showAlert = true
    this.alertMessage = `Stai passando alla modalità ${mode}, tutti i valori dell'attuale modalità verranno resettati. Procedere lo stesso?`
  }

  switchFamilyMode(mode: familyMode) {
    //this.familyPricingMode = mode
  }

  confirmSwitch(event: boolean) {
    if (event === true) {
      this.caregiverPricingMode = this.localMode
      if (this.caregiverPricingMode === caregiverMode.FORFETTARIA) {
        this.form.get('netHourCost')?.setValue(0)
      } else {
        this.form.get('flatCost')?.setValue(0)
        this.form.get('flatCostTiming')?.setValue('0')
      }
      this.showAlert = false

    } else {
      this.showAlert = false
    }
  }

  initSwitchCaregiverMode() {
    const flatCost = this.request.flatCost
    if (flatCost !== 0) {
      this.caregiverPricingMode = caregiverMode.FORFETTARIA
    } else {
      this.caregiverPricingMode = caregiverMode.NETTA
    }
  }

  initSwitchFamilyMode() {
    if (this.reqService.customerType === 'B2B') {
      this.familyPricingMode = familyMode.COSTO_PACCHETTO_FORFETTARIO
    } else if (this.reqService.name === 'Pacchetto ore') {
      this.familyPricingMode = familyMode.PACCHETTO
    } else {
      this.familyPricingMode = familyMode.SERVIZIO
    }
  }

  initForm() {
    this.form.setValue({
      taxisitter: this.request.taxisitter,
      hoursAmount: this.request.hoursAmount,
      netHourCost: this.request.netHourCost,
      flatCost: this.request.flatCost,
      flatCostTiming: this.request.flatCostTiming !== null ? this.request.flatCostTiming.toString() : null,
      packageFlatCost: this.request.packageFlatCost,
      withholdingTax: this.request.withholdingTax,
      moreThanOne: this._moreThanOne,
      handicap: this._handicap,
      badWorkHours: this._badWorkHours,
      continuousJob: this._continuousJob,
      inconvenientLocation: this._inconvenientLocation
    })
  }

  // convertFlatTimingToString(timing: number): string {
  //   switch (timing) {
  //     case 0:
  //       return 'mese';
  //     case 1:
  //       return 'settimana';
  //     case 2:
  //       return 'ora';
  //     case 3:
  //       return 'una tantum';
  //     case 4:
  //       return 'giorno';
  //     case 5:
  //       return 'notte';
  //     case 6:
  //       return 'passaggio';
  //     default:
  //       return '';
  //   }
  // }

  //CAREGIVER PRICING MESSAGE
  generateCaregiverPricingMessage() {
    const retribuzioneTata = this.computeNetHourCostByService(this.flatCost!, parseInt(this.flatCostTiming!), this.province,
      this.moreThanOne!, this.handicap!, this.badWorkHours!, this.netHourCost!, this.reqService.name, this.withholdingTax!);
    const text4 = this.taxisitter
      ? " + rimborso spese benzina"
      : '';
    return `Remunerazione netta: ${retribuzioneTata}${text4}`
  }

  computeNetHourCostByService(flatCost: number, flatCostTiming: number, province: string, moreThanOne: boolean, handicap: boolean, badWorkHours: boolean, netHourCost: number, service: string, withholdingTax = false) {
    let pricing = '';
    if (!(flatCost !== null && flatCost > 0)) {
      const caregiverPricing: CaregiverPricing = {
        provincia: this.province,
        moreThanOne: this.moreThanOne,
        handicap: this.handicap,
        badWorkHours: this.badWorkHours,
        netHourCost: netHourCost,
        businessLine: this.reqService.businessLine,
        continousJob: this.continuousJob,
        inconvenientLocation: this.inconvenientLocation
      };
      if (flatCostTiming === 6) {
        const netCostHour: number = this.utilities.computeNetPriceV2(caregiverPricing);
        pricing = `${netCostHour}€/ passaggio`;
        if (service === 'Pacchetto ore' || withholdingTax) {
          return `${pricing} (${(netCostHour / 1.25).toFixed(2)}€ mediante Bonifico + ${(netCostHour * 0.2).toFixed(2)}€ in Rit.Acconto)`;
        } else {
          return pricing;
        }
      } else {
        const netCostHour: number = this.utilities.computeNetPriceV2(caregiverPricing);
        pricing = `${netCostHour}€/ora`;
        if (service === 'Pacchetto ore' || withholdingTax) {
          return `${pricing} (${(netCostHour / 1.25).toFixed(2)}€ mediante Bonifico + ${(netCostHour * 0.2).toFixed(2)}€ in Rit.Acconto)`;
        } else {
          return pricing;
        }
      }
    } else {
      pricing = this.utilities.computeFlatPrice(flatCost, flatCostTiming);
      if (service === 'Pacchetto ore' || withholdingTax) {
        return `${pricing} (${(flatCost / 1.25).toFixed(2)}€ mediante Bonifico + ${(flatCost * 0.2).toFixed(2)}€ in Rit.Acconto)`;
      } else {
        return pricing;
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
