import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReferralObjInterface } from 'src/app/shared/models/referral-object.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralProgramService {

  baseUrl = environment.baseURL

  constructor(private http:HttpClient) { }

  getCodeFromReqID(id:number){
    return this.http.get<ReferralObjInterface>(`${this.baseUrl}/referral-usage/${id}`)
  }

  activateReferralCode(body:any){
    return this.http.post<ReferralObjInterface>(`${this.baseUrl}/referral-usage/use`, body)
  }
}
