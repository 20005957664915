import { ReferralObjInterface } from './../../shared/models/referral-object.interface';
import { ReferralProgramService } from './../referral-program-service/referral-program.service';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { catchError, map, Observable, of, Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/shared/services/toast-service/toast.service';

@Component({
  selector: 'app-ref-code-panel',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, NgbTooltipModule],
  templateUrl: './ref-code-panel.component.html',
  styleUrl: './ref-code-panel.component.scss'
})
export class RefCodePanelComponent implements OnInit, OnDestroy {

  @Input() RequestID!: number;
  @Input() RequestEmail!: string;

  @Output() hasReferrral = new EventEmitter<boolean>();
  @Output() isActiveReferral = new EventEmitter<boolean>();

  readonly = false;
  errorMessage!: string;
  active = false;

  private unsubscibe = new Subject<void>()

  form = new FormGroup({
    referralCode: new FormControl('', [Validators.required, Validators.pattern(new RegExp('^[a-zA-Z0-9]{8}$'))]),
    emailAmbassador: new FormControl('', [Validators.required, Validators.email]),
  })

  constructor(private toastService: ToastService, private referralProgramService: ReferralProgramService) { }

  ngOnInit(): void {
    this.referralProgramService.getCodeFromReqID(this.RequestID).pipe(
      map((referral: ReferralObjInterface) => {
        this.form.patchValue({
          referralCode: referral.referralCode.code,
          emailAmbassador: referral.referralCode.email
        });
        this.readonly = true;
        this.initActivationCode();
        if (referral.referralCode.active !== true) {
          this.errorMessage = 'Il codice referral non è ancora stato attivato per questa richiesta!'
          this.hasReferrral.emit(true)
          this.isActiveReferral.emit(false)
        } else {
          this.active = true;
          this.hasReferrral.emit(true)
          this.isActiveReferral.emit(true)
        }
      }
      ),
      catchError((err: any) => {
        if (err.status = 404) {
          this.active = false;
          this.hasReferrral.emit(false);
          this.readonly = false;
        }
        return of()
      }),
      takeUntil(this.unsubscibe)
    ).subscribe()

    this.form.valueChanges.pipe(
      map(() => {
        if (!this.form.invalid) {
          this.active = true;
        } else {
          this.active = false;
        }
      }),
      takeUntil(this.unsubscibe)
    ).subscribe()
  }

  get referralCode() {
    return this.form.get('referralCode')
  }

  get emailAmbassador() {
    return this.form.get('emailAmbassador')
  }

  activatedCode() {
    if (!this.form.invalid) {
      let body = {
        code: this.referralCode!.value,
        newCustomerEmail: this.RequestEmail,
        requestID: this.RequestID
      }
      this.referralProgramService.activateReferralCode(body).pipe(
        map(() => {
          this.isActiveReferral.emit(true);
          this.hasReferrral.emit(true);
          this.toastService.setAction('Codice attivato con successo!', true)}),
        catchError((err: any) => {
          this.isActiveReferral.emit(false);
          this.toastService.setAction("Errore durante l'attivazione del codice", false)
          return of()
        }),
        takeUntil(this.unsubscibe)
      ).subscribe()
    }
  }

  initActivationCode() {
    if (!this.form.invalid) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  copyToClipboard(event: any) {

    const target = event.target as HTMLElement;
    const id = target.getAttribute('data-id');

    if (id === 'referral') {
      navigator.clipboard.writeText(this.referralCode!.value!);
      this.toastService.setAction('Dati copiati con successo! Ora puoi incollarli dove vuoi.', true)
    } else {
      navigator.clipboard.writeText(this.emailAmbassador!.value!);
      this.toastService.setAction('Dati copiati con successo! Ora puoi incollarli dove vuoi.', true)
    }
  }

  ngOnDestroy(): void {
    this.unsubscibe.next();
    this.unsubscibe.complete()
  }
}
