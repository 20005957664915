import { GoogleMapsService } from './../caregiver-scoreboard/caregiver-selection/caregiver-selection-service/google-maps.service';
import { RequestPersonalInfo, FHService, ProvinceFilterComponent } from 'npx-family-happy-common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserStorageService } from 'src/app/authentication/user-storage/user-storage.service';
import { environment } from 'src/environments/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { Subject, combineLatest, takeUntil } from 'rxjs';
import { ServiceServices } from 'src/app/backoffice/services/service-services/service.services';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-request-personal-info',
  templateUrl: './request-personal-info.component.html',
  styleUrls: ['./request-personal-info.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, ProvinceFilterComponent]
})
export class RequestPersonalInfoComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() inputData!: RequestPersonalInfo;
  @Input() requestID!: number;
  @Input() requestCategory!: string;
  @Output() dataUpdated = new EventEmitter<{ requestID: number, requestPersonalInfo: RequestPersonalInfo, oldAddress:string }>();
  @Output() addressUpdated = new EventEmitter<any>();


  form = new UntypedFormGroup({
    id: new UntypedFormControl(null, Validators.required),
    operator: new UntypedFormControl('', Validators.required),
    customer: new UntypedFormControl('', Validators.required),
    service: new UntypedFormControl('', Validators.required),
    contactType: new UntypedFormControl('', [Validators.required]),
    contactDate: new UntypedFormControl('', [Validators.required]),
    contactHours: new UntypedFormControl('', Validators.required),
    contactPreference: new UntypedFormControl('', Validators.required),
    phoneDate: new UntypedFormControl('', Validators.required),
    parentName: new UntypedFormControl('', Validators.required),
    parentSurname: new UntypedFormControl('', Validators.required),
    parentEmail: new UntypedFormControl('', Validators.required),
    parentPhone: new UntypedFormControl('', Validators.required),
    parentCity: new UntypedFormControl('', Validators.required),
    parentZone: new UntypedFormControl('', Validators.required),
    parentAddress: new UntypedFormControl('', Validators.required),
    parentCap: new UntypedFormControl('', Validators.pattern(new RegExp('^\\d{5}$'))),
    provincia: new UntypedFormControl('TO', Validators.required),
    expDate: new UntypedFormControl('', Validators.required),
    manualExpDate: new UntypedFormControl('', Validators.required),
    subscription: new UntypedFormControl(''),
    manualSubscription: new UntypedFormControl(''),
    referral: new UntypedFormControl(''),
    message: new UntypedFormControl('', Validators.required),
    company: new UntypedFormControl(false),
    companySize: new UntypedFormControl(''),
    welfareCompany: new UntypedFormControl(''),
    requestCategory: new UntypedFormControl(''),
    parentFiscalCode: new UntypedFormControl(''),
    serviceLinked: new UntypedFormControl(),
    latitude: new UntypedFormControl(null),
    longitude: new UntypedFormControl(null)
  })
  showOperatorSwap = false;
  servicesAvailable: FHService[] = [];
  customerTypesAvailable: string[] = [];

  private currentUser!: string | undefined;
  private unsubscribe = new Subject<void>();
  private oldAddress!:string

  constructor(
    private userStorage: UserStorageService,
    private serviceService: ServiceServices,
    private googleMapsService:GoogleMapsService
  ) { }

  ngOnInit(): void {
    this.userStorage.getUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => {
      this.currentUser = user?.name;
      if (user?.name !== this.inputData.operator) {
        this.showOperatorSwap = true;
      } else {
        this.showOperatorSwap = false;
      }
    })
    // Search for services available
    const customerObs = this.form.get('customer')!.valueChanges;
    const businessLineObs = this.form.get('requestCategory')!.valueChanges;
    combineLatest([customerObs, businessLineObs]).pipe(takeUntil(this.unsubscribe)).subscribe(([customerType, businessLine]) => {
      this.serviceService.getAllServices(0, 20, { searchKey: null, businessLine, customerType, disabled: false }).subscribe((result) => {
        this.servicesAvailable = result.content;
        this.customerTypesAvailable = [...new Set(result.content.map(item => item.customerType))];
      });
    });
    this.initForm();
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe((value) => {
      const requestID = value.id;
      delete value.id;
      const requestPersonalInfo:RequestPersonalInfo = {
        ...value,
        expDate: value.expDate !== null && value.expDate !== '' ? new Date(value.expDate) : null,
        manualExpDate: value.manualExpDate !== null && value.manualExpDate !== '' ? new Date(value.manualExpDate) : null,
      }
      if(this.form.get('parentAddress')!.value === ''){
        requestPersonalInfo.latitude = 0
        requestPersonalInfo.longitude = 0
      }
      const oldAddress = this.oldAddress
      this.dataUpdated.emit({
        requestID,
        requestPersonalInfo,
        oldAddress
      });
    })
    // Patch request if serviceLinked is not available
    if (this.inputData.serviceLinked === null) {
      this.serviceService.getAllServices(0, 10, { searchKey: this.inputData.service, businessLine: this.inputData.requestCategory, customerType: this.inputData.customer, disabled: null }).subscribe((services) => {
        // Find the service with the exact service name
        const service = services.content.find(item => item.name === this.inputData.service);
        if (service) {
          alert(`Durante l'ultimo aggiornamento, è stata cambiata la logica con cui vengono gestiti i servizi collegati alle richieste. Quando si apre la richiesta, se il servizio non è ancora stato collegato correttamente, avrai questo avviso e ti sarà chiesto di salvare le modifiche. Effettua il salvataggio e questo alert scomparirà.`)
          this.inputData.serviceLinked = service;
          this.form.get('serviceLinked')?.setValue(service);
        }
      })
    }
  }

  ngAfterViewInit(): void {
    this.loadGoogleMaps(document.getElementById('indirizzogenitore') as HTMLInputElement);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  operatorSwap() {
    this.form.get('operator')?.setValue(this.currentUser);
    this.form.markAsDirty();
  }

  updateProvincia(newValue: string) {
    this.form.get('provincia')?.setValue(newValue);
    this.form.markAllAsTouched();
    this.form.get('provincia')?.markAsDirty();
  }

  loadGoogleMaps(input: HTMLInputElement) {
    input.addEventListener('change', () => {
      input.value = '';
      this.form.get('parentAddress')?.setValue('', { emitEvent: false });
    });

    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      const autocomplete = new google.maps.places.Autocomplete(input);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let formAddress = this.form.get('parentAddress');

        formAddress?.setValue(place.formatted_address);

        // Compile other address fields according to place object
        place.address_components?.forEach((component: any) => {
          if (component.types.includes('locality')) {
            this.form.get('parentCity')?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            this.form.get('parentCap')?.setValue(component.long_name);
          }
        });
        this.addressUpdated.emit(place.formatted_address)
      })
    });
  }

  selectService(event: Event) {
    const selectedServiceName = (event.target as HTMLSelectElement).value;
    const service = this.servicesAvailable.find(service => service.name === selectedServiceName);
    this.form.get('serviceLinked')?.setValue(service);
  }

  private initForm() {
    this.form.setValue({
      ...this.inputData,
      id: this.requestID,
      expDate: this.inputData.expDate ? this.inputData.expDate.toISOString().split('T')[0] : '',
      manualExpDate: this.inputData.manualExpDate ? this.inputData.manualExpDate.toISOString().split('T')[0] : ''
    })
    this.oldAddress = this.inputData.parentAddress
  }
}
