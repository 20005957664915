<div>
  <div class="my-2">
    <h2>Referral Program</h2>
  </div>
  <div class="my-4">
    <div class="fh-form-grid" [formGroup]="form">
      <div class="rcp-form-input-field">
        <label class="fw-semibold" for="referredCode">Codice Ambassador:</label>
        <input [readOnly]="readonly" type="text" id="referredCode" formControlName="referralCode">
        <div>
          <i class="bi bi-clipboard" placement="top" ngbTooltip="Copia codice"
          (click)="copyToClipboard($event)" data-id="referral"></i>
        </div>
      </div>
      <div class="rcp-form-input-field">
        <label class="fw-semibold" for="referredCode">Email Ambassador:</label>
        <input [readOnly]="readonly" type="text" id="referredCode" formControlName="emailAmbassador">
        <div>
          <i class="bi bi-clipboard" placement="top" ngbTooltip="Copia email"
          (click)="copyToClipboard($event)" data-id="referred"></i>
        </div>
      </div>
    </div>
  </div>
  <div>
    @if(errorMessage){
      <div class="alert alert-danger text-center" role="alert">
        {{errorMessage}}
      </div>
    }
    @if(active){
      <div class="d-flex justify-content-end">
        <div>
          <button class="btn btn-secondary mt-2 text-white" (click)="activatedCode()">Attiva codice</button>
        </div>
      </div>
    }
  </div>
</div>
