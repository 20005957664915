<div id="cv-page-container" [ngClass]="{ 'cv-preview': previewMode }"
  *ngIf="_colf">
  <div id="blue-tr-decorative"></div>
  <div id="pink-tr-decorative"></div>
  <div id="img-container">
    <img [src]="_colf.info.photo" alt>
  </div>
  <div id="fh-banner">
    <img src="/assets/logo_banner.png" alt>
  </div>
  <div id="registry-info">
    <h2>{{_colf.info.name}}</h2>
    <p>{{_colf.info.age}} anni</p>
  </div>
  <div class="d-grid w-100 skills-container">
    <div id="left-column">
      <div id="about-me-section">
        <div class="title-with-icon">
          <p>Su di me</p>
        </div>
        <p class="text"
          style="font-size: 10pt; line-height: 14px;">{{_colf.info.description}}</p>
      </div>

      <div id="school-section" style="margin-top: -10px">
        <div class="title-with-icon">
          <p>Formazione</p>
        </div>
        <div class="text d-grid my-2">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;">Certificazione
              Family+Happy</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngFor="let cert of certificates">{{cert}}</li>
          </ul>
        </div>
      </div>

      <div id="language-section" style="margin-top: -10px">
        <div class="title-with-icon">
          <p>Lingue parlate</p>
        </div>
        <div class="text d-grid my-2">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.lang.italian === 'Madrelingua' || _colf.lang.italian === 'Ottimo'">Italiano</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.lang.english === 'Madrelingua' || _colf.lang.english === 'Ottimo'">Inglese</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.lang.spanish === 'Madrelingua' || _colf.lang.spanish === 'Ottimo'">Spagnolo</li>
            <li style="font-size: 10pt; line-height: 14px;">Referenze su
              richiesta</li>
          </ul>
        </div>
      </div>
    </div>
    <div id="right-column">
      <div id="experience-section" *ngIf="_colf.exp.total > 0">
        <div class="title-with-icon mb-3">
          <div class="title-icon">

          </div>
          <p>Esperienza</p>
        </div>
        <div class="row">
          <div class="col-6 experience-cluster"
            *ngIf="_colf.exp.cluster1 && _colf.exp.cluster1.cluster1Age > 0">
            <div class="title-with-icon">
              <p>Presso famiglie:</p>
              <p>{{ _colf.exp.cluster1.cluster1Age > 1 ?
                _colf.exp.cluster1.cluster1Age + ' anni' : '1 anno' }}</p>
            </div>
          </div>
          <div class="col-6 experience-cluster"
            *ngIf="_colf.exp.cluster2 && _colf.exp.cluster2.cluster2Age > 0">
            <div class="title-with-icon">
              <p>Presso ditte o strutture:</p>
              <p>{{ _colf.exp.cluster2.cluster2Age > 1 ?
                _colf.exp.cluster2.cluster2Age + ' anni' : '1 anno' }}</p>
              <p>({{_colf.exp.cluster2.employer}})</p>
            </div>
          </div>
        </div>
      </div>
      <div id="availabilities">
        <div style="margin-top: 10px" class="title-with-icon">
          <p>Disponibilità</p>
        </div>
        <div class="text d-grid mb-3">
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.houseCleaning">Pulizia e lavori
              domestici
            </li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.ironing">Stiro</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.cooking">Preparazione Pasti </li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.sewing">Cucito/ Aggiustamento abiti
            </li>
          </ul>
          <ul class="m-0">
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.groceries">Aiuto con la spesa</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.windowCleaning">Pulizie Tapparelle/
              zanzariere/ vetri</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.terraceCleaning">Pulizia Terrazzi</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.washingMachines">Lavatrici</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.gardening">Giardinaggio</li>
            <li style="font-size: 10pt; line-height: 14px;"
              *ngIf="_colf.avail.helping">Aiuto Commissioni</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div id="footer">
    <p id="footer-title">Risultato Test psicoattitudinale</p>
    <!-- <p id="footer-text">{{attitude}}</p> -->
    <p id="footer-text">
      Questo profilo risulta essere accogliente e comprensivo. Caratterizzato da
      grande empatia e profondo rispetto
      per
      le esigenze e le emozioni delle persone con cui si rapporta, creando così
      un ambiente di cura basato sulla
      fiducia
      reciproca. La sua presenza rassicurante e il suo atteggiamento positivo
      contribuiscono a creare un clima di
      tranquillità e conforto. Ha un grande senso di responsabilità e si impegna
      costantemente per garantire il
      benessere e la sicurezza.
    </p>
    <div>
      <p id="footer-subtext">Test Sviluppato con la Facoltà di Psicologia
        dell’Università di Torino</p>
      <span id="id-babysitter">{{_colf.docRef}}</span>
    </div>
  </div>
</div>

<div *ngIf="previewMode" class="page-buttons">
  <img src="../../../../assets/icons/previous-icon.svg"
    (click)="changePage(false)"
    [ngStyle]="{ 'opacity': currentPage > 0 ? '100%' : '50%', 'cursor': currentPage > 0 ? 'pointer' : 'default' }" />
  <button (click)="sendCV()">Invia</button>
  <img src="../../../../assets/icons/next-icon.svg" (click)="changePage(true)"
    [ngStyle]="{ 'opacity': currentPage < pages.length - 1 ? '100%' : '50%', 'cursor': currentPage < pages.length - 1 ? 'pointer' : 'default' }" />
</div>
