import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { CaregiverPricing, FHService, RequestCategory, RequestDetail, RequestPetsitterDetail, RequestUtilitiesService } from 'npx-family-happy-common';
import { Subject, takeUntil } from 'rxjs';
import { BabySitterService } from 'src/app/caregiver/babysitter/baby-sitter-service/baby-sitter-service.service';
import { PetsitterService } from 'src/app/caregiver/petsitter/petsitter-service/petsitter-service.service';
import { RequestPricingComponent } from '../request-pricing/request-pricing.component';

@Component({
  selector: 'app-request-petsitter-detail-form',
  templateUrl: './request-petsitter-detail-form.component.html',
  styleUrls: ['./request-petsitter-detail-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RequestPricingComponent]
})
export class RequestPetsitterDetailFormComponent implements OnInit, OnDestroy {
  @Input() inputData!: RequestPetsitterDetail;
  @Input() requestID!: number;
  @Input() provincia!: string;
  @Input() reqService!: FHService;
  @Output() dataUpdated = new EventEmitter<RequestPetsitterDetail>();


  form = new UntypedFormGroup({
    catHealing: new UntypedFormGroup({
      cat: new UntypedFormControl(false),
    }),
    catHosting: new UntypedFormGroup({
      cat: new UntypedFormControl(false),
    }),
    dogHealing: new UntypedFormGroup({
      dog: new UntypedFormControl(false),
      dog1: new UntypedFormControl(false),
      dog2: new UntypedFormControl(false),
      dog3: new UntypedFormControl(false),
      dog4: new UntypedFormControl(false),
    }),
    dogHosting: new UntypedFormGroup({
      dog: new UntypedFormControl(false),
      dog1: new UntypedFormControl(false),
      dog2: new UntypedFormControl(false),
      dog3: new UntypedFormControl(false),
      dog4: new UntypedFormControl(false),
    }),
    requestedExp: new UntypedFormGroup({
      requestedExpCluster1: new UntypedFormControl(false),
      requestedExpCluster2: new UntypedFormControl(false),
      requestedExpCluster3: new UntypedFormControl(false),
    }),
    requestedAge: new UntypedFormGroup({
      requestedAgeCluster1: new UntypedFormControl(false),
      requestedAgeCluster2: new UntypedFormControl(false),
      requestedAgeCluster3: new UntypedFormControl(false)
    }),
    starting: new UntypedFormControl('', Validators.required),
    estimatedLasting: new UntypedFormControl('', Validators.required),
    serviceStart: new UntypedFormControl('', Validators.required),
    serviceDuration: new UntypedFormControl('', Validators.required),
    continuousJob: new UntypedFormControl(false, Validators.required),
    moreThanOne: new UntypedFormControl(false, Validators.required),
    badWorkHours: new UntypedFormControl(false, Validators.required),
    taxisitter: new UntypedFormControl(false),
    hoursAmount: new UntypedFormControl(0),
    netHourCost: new UntypedFormControl(0),
    flatCost: new UntypedFormControl(0),
    flatCostTiming: new UntypedFormControl(0),
    packageFlatCost: new UntypedFormControl(0),
    coliving: new UntypedFormControl(false),
    relocation: new UntypedFormControl(false),
    withholdingTax: new UntypedFormControl(false),
    inconvenientLocation: new UntypedFormControl(false),
    days: new UntypedFormGroup({
      sun: new UntypedFormControl(false),
      mon: new UntypedFormControl(false),
      tue: new UntypedFormControl(false),
      wed: new UntypedFormControl(false),
      thu: new UntypedFormControl(false),
      fri: new UntypedFormControl(false),
      sat: new UntypedFormControl(false),
    }),
    hours: new UntypedFormGroup({
      mor: new UntypedFormControl(false),
      aft: new UntypedFormControl(false),
      eve: new UntypedFormControl(false),
    }),
    requestDescription: new UntypedFormControl('', Validators.required),
    walking: new UntypedFormControl(false),
    pension: new UntypedFormControl(false),
    homeService: new UntypedFormControl(false),
    nightShift: new UntypedFormControl(false),
    hosting: new UntypedFormControl(false),
    grooming: new UntypedFormControl(false),
    veterinarian: new UntypedFormControl(false),
    cleaning: new UntypedFormControl(false),
    license: new UntypedFormControl(false),
    car: new UntypedFormControl(false),
    drugs: new UntypedFormControl(false),
    disability: new UntypedFormControl(false),
    mentalIllness: new UntypedFormControl(false),
  })

  private unsubscribe = new Subject<void>();


  constructor(private utilities: RequestUtilitiesService, private petsitterService: PetsitterService) { }

  ngOnInit(): void {
    this.initForm();
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((_) => {
        this.pushDataChanges();
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateNetHourPrice() {
    const formValue = this.form.value;
    const caregiverPricing: CaregiverPricing = {
      provincia: this.provincia,
      moreThanOne: formValue.moreThanOne,
      handicap: formValue.handicap,
      badWorkHours: formValue.badWorkHours,
      netHourCost: null,
      businessLine: RequestCategory.BABYSITTER,
      continousJob: formValue.continuousJob,
      inconvenientLocation: formValue.inconvenientLocation
    };
    this.form.get('netHourCost')?.setValue(
      this.utilities.computeNetPriceV2(caregiverPricing));
    this.pushDataChanges();
  }

  decreaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseNetHourCostByOne() {
    const formControl = this.form.get('netHourCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  decreaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value > 1) {
        formControl.setValue(value - 1);
      } else {
        formControl.setValue(0);
      }
    }
  }

  increaseFlatCostByOne() {
    const formControl = this.form.get('flatCost');
    if (formControl) {
      const value = formControl.value;
      if (value >= 0) {
        formControl.setValue(value + 1);
      } else {
        formControl.setValue(1);
      }
    }
  }

  private initForm() {
    this.form.setValue({
      starting: this.inputData.starting,
      estimatedLasting: this.inputData.estimatedLasting,
      serviceStart: this.inputData.serviceStart,
      serviceDuration: this.inputData.serviceDuration,
      continuousJob: this.inputData.continuousJob,
      moreThanOne: this.inputData.moreThanOne,
      badWorkHours: this.inputData.badWorkHours,
      taxisitter: this.inputData.taxisitter,
      hoursAmount: this.inputData.hoursAmount,
      netHourCost: this.inputData.netHourCost,
      flatCost: this.inputData.flatCost,
      flatCostTiming: this.inputData.flatCostTiming,
      packageFlatCost: this.inputData.packageFlatCost,
      coliving: this.inputData.coliving,
      relocation: this.inputData.relocation,
      withholdingTax: this.inputData.withholdingTax,
      inconvenientLocation: this.inputData.inconvenientLocation,
      requestDescription: this.inputData.requestDescription,
      walking: this.inputData.walking,
      pension: this.inputData.pension,
      homeService: this.inputData.homeService,
      nightShift: this.inputData.nightShift,
      hosting: this.inputData.hosting,
      grooming: this.inputData.grooming,
      veterinarian: this.inputData.veterinarian,
      cleaning: this.inputData.cleaning,
      license: this.inputData.license,
      car: this.inputData.car,
      drugs: this.inputData.drugs,
      disability: this.inputData.disability,
      mentalIllness: this.inputData.mentalIllness,
      catHealing: this.petsitterService.fromStringToAvailObject(this.inputData.catHealing, 'catHealing'),
      catHosting: this.petsitterService.fromStringToAvailObject(this.inputData.catHosting, 'catHosting'),
      dogHealing: this.petsitterService.fromStringToAvailObject(this.inputData.dogHealing, 'dogHealing'),
      dogHosting: this.petsitterService.fromStringToAvailObject(this.inputData.dogHosting, 'dogHosting'),
      requestedExp: {
        requestedExpCluster1: this.inputData.requestedExp ? this.inputData.requestedExp.includes('Cuccioli') : false,
        requestedExpCluster2: this.inputData.requestedExp ? this.inputData.requestedExp.includes('Adulti') : false,
        requestedExpCluster3: this.inputData.requestedExp ? this.inputData.requestedExp.includes('Anziani') : false,
      },
      requestedAge: {
        requestedAgeCluster1: this.inputData.requestedAge ? this.inputData.requestedAge.includes('18-35') : false,
        requestedAgeCluster2: this.inputData.requestedAge ? this.inputData.requestedAge.includes('36-50') : false,
        requestedAgeCluster3: this.inputData.requestedAge ? this.inputData.requestedAge.includes('51+') : false
      },
      days: this.petsitterService.fromStringToAvailObject(this.inputData.workDays, 'days'),
      hours: this.petsitterService.fromStringToAvailObject(this.inputData.workHours, 'hours')
    });
  }

  private pushDataChanges() {
    const data = this.form.value;

    let requestedAge: string[] = [];
    Object.entries<boolean>(data.requestedAge).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedAgeCluster1':
            requestedAge.push('18-35');
            break;

          case 'requestedAgeCluster2':
            requestedAge.push('36-50');
            break;

          case 'requestedAgeCluster3':
            requestedAge.push('51+');
            break;

          default:
            break;
        }
      }
    })

    let requestedExp: string[] = [];
    Object.entries<boolean>(data.requestedExp).forEach((value) => {
      if (value[1]) {
        switch (value[0]) {
          case 'requestedExpCluster1':
            requestedExp.push('Cuccioli');
            break;

          case 'requestedExpCluster2':
            requestedExp.push('Adulti');
            break;

          case 'requestedExpCluster3':
            requestedExp.push('Anziani');
            break;

          default:
            break;
        }
      }
    })

    const newPetInfo: RequestPetsitterDetail = {
      starting: data.starting,
      estimatedLasting: data.estimatedLasting,
      serviceStart: data.serviceStart,
      serviceDuration: data.serviceDuration,
      continuousJob: data.continuousJob,
      moreThanOne: data.moreThanOne,
      badWorkHours: data.badWorkHours,
      taxisitter: data.taxisitter,
      hoursAmount: data.hoursAmount,
      netHourCost: data.netHourCost,
      flatCost: data.flatCost,
      flatCostTiming: +data.flatCostTiming,
      withholdingTax: data.withholdingTax,
      inconvenientLocation: data.inconvenientLocation,
      requestDescription: data.requestDescription,
      requestedAge,
      requestedExp,
      workHours: this.petsitterService.fromAvailObjectToString(data.hours, 'hours'),
      workDays: this.petsitterService.fromAvailObjectToString(data.days, 'days'),
      catHealing: this.petsitterService.fromAvailObjectToString(data.catHealing, 'catHealing'),
      catHosting: this.petsitterService.fromAvailObjectToString(data.catHosting, 'catHosting'),
      dogHealing: this.petsitterService.fromAvailObjectToString(data.dogHealing, 'dogHealing'),
      dogHosting: this.petsitterService.fromAvailObjectToString(data.dogHosting, 'dogHosting'),
      car: data.car,
      license: data.license,
      homeService: data.homeService,
      pension: data.pension,
      walking: data.walking,
      hosting: data.hosting,
      grooming: data.grooming,
      veterinarian: data.veterinarian,
      cleaning: data.cleaning,
      nightShift: data.nightShift,
      drugs: data.drugs,
      mentalIllness: data.mentalIllness,
      disability: data.disability,
      packageFlatCost: data.packageFlatCost,
      coliving: data.coliving,
      relocation: data.relocation,
    }
    this.dataUpdated.emit(newPetInfo);
  }

  updatePricing(pricing: any) {
    this.form.setValue({
      ...this.form.value,
      taxisitter: pricing.taxisitter,
      hoursAmount: pricing.hoursAmount,
      netHourCost: pricing.netHourCost,
      flatCost: pricing.flatCost,
      flatCostTiming: pricing.flatCostTiming,
      packageFlatCost: pricing.packageFlatCost,
      withholdingTax: pricing.withholdingTax,
    })
  }

}
